import { Injectable } from '@angular/core';
import { UserInfo } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, firstValueFrom, of, switchMap } from 'rxjs';
import { Company } from 'src/app/models/Company';
import { UserAuthModel, UserCredentialModel, UserModel } from 'src/app/models/UserModel';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loadedOnce: boolean;
  userAuth!: Observable<UserModel | null | undefined>;
  userSessionToken!: string;
  userSessionData!: UserModel;
  userEtcData!: Observable<Company | undefined>;

  idTokenUser!: Observable<string | null>;
  private rememberPass!: boolean;

  editAlertForRedirect: boolean;
  editAlertForRedirectStr: string;

  constructor(private angularFireAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private router: Router,
    private userService: UserService,
  ) {
    this.loadedOnce = false;
    this.editAlertForRedirect = false;
    this.editAlertForRedirectStr = 'SEM STR NENHUMA';
    this.configUserObservables();
  }

  createNewAccount(account: any): Promise<UserCredentialModel> {
    let userAuth: UserCredentialModel;
    return new Promise((resolve, reject) => {
      this.angularFireAuth.createUserWithEmailAndPassword(account.email, account.password).then((userCredential) => {
        userAuth = {
          uid: userCredential.user!.uid,
          email: userCredential.user!.email ?? '',
          emailVerified: userCredential.user!.emailVerified,
          erroMsg: ''
        };
        resolve(userAuth);
      }).catch(e => {
        if (e && e.code) {
          let error: string = '';
          if (e.code === 'auth/email-already-in-use') {
            error = 'email-already-in-use';
          } else if (e.code === 'auth/wrong-password') {
            error = 'wrong-password';
          } else if (e.code === 'auth/invalid-email') {
            error = 'invalid-email';
          } else if (e.code === 'auth/argument-error') {
            error = 'argument-error';
          }
          reject(error);
        } else {
          console.error('login on e-mail pwd error: ', e.code);
          reject('error');
        }
      });
    });
  }

  loginByEmailPassword(login: any) {
    return new Promise((resolve, reject) => {
      this.rememberPass = login.rememberPass;
      this.angularFireAuth.signInWithEmailAndPassword(login.email, login.password).then(credential => {
        this.updateUserWithAuth(credential.user);
        resolve(credential.user);
      }).catch(e => {
        if (e) {
          let error: string = '';
          if (e.code === 'auth/user-not-found') {
            error = 'user';
          } else if (e.code === 'auth/wrong-password') {
            error = 'password';
          } else if (e.code === 'auth/invalid-email') {
            error = 'invalid-email';
          }
          reject(error);
        } else {
          console.error('login on e-mail pwd error: ', e.code);
          reject('error');
        }
      });
    });
  }

  appClosed() {
    if (!this.rememberPass) { this.signOut(true); }
  }

  signOut(isRedirect: boolean) {
    this.angularFireAuth.signOut().then(() => {
      if (isRedirect) {
        this.editAlertForRedirect = false;
        this.editAlertForRedirectStr = '';
        this.router.navigate(['/authentication/login']);
      }
    }).catch(e => {
      console.error('sign out error: ', e);
    });
  }

  async signOutNoRedirect() {
    try {
      await this.angularFireAuth.signOut();
    } catch (e) {
      console.error('signOut', e);
    }
  }

  changePassword(code: string, password: string): Promise<any> {
    const promise = new Promise<void>((resolve, reject) => {
      this.angularFireAuth.confirmPasswordReset(code, password).then(() => {
        resolve();
      }).catch(error => {
        console.log(error);
        reject();
      });
    });
    return promise;
  }

  forgotPassword(email: string): Promise<any> {
    const promise = new Promise<void>((resolve, reject) => {
      this.angularFireAuth.sendPasswordResetEmail(email).then(() => {
        resolve();
      }).catch(error => {
        console.log(error);
        reject();
      });
    });
    return promise;
  }

  async confirmResetCode(actionCode: string) {
    const auth = (await this.angularFireAuth.app).auth();
    try {
      const email = await auth.verifyPasswordResetCode(actionCode);
      return { email: email, error: false };
    } catch (error: any) {
      console.log('Problemas ao tentar validar o código de reset de senha', error);
      return { email: '', error: error };
    }
  }

  async resetPassword(actionCode: string, newPassword: string, userEmail: string) {
    const auth = (await this.angularFireAuth.app).auth();
    try {
      const email = await auth.verifyPasswordResetCode(actionCode);
      const accountEmail = email;
      if (userEmail === accountEmail) {
        auth.confirmPasswordReset(actionCode, newPassword).then(() => {
          console.log('Password reset has been confirmed and new password updated');
        }).catch(error => {
          console.log('Problemas ao tentar redefinir uma senha', error);
          throw new Error('Erro ao tentar alterar a senha');
        });
      } else {
        throw new Error('E-mail inválido.');
      }
    } catch (error_1) {
      console.log('Problemas ao tentar validar o código de reset de senha', error_1);
      throw new Error('Erro ao validar o código de verificação.');
    }
  }

  getHeader(FinancialPass?: String): any {
    if (FinancialPass) {
      return {
        'headers': {
          'Authorization': 'Bearer ' + this.userSessionToken,
          'Content-Type': 'application/json',
          'financialPass': FinancialPass
        }
      };
    } else {
      return {
        'headers': {
          'Authorization': 'Bearer ' + this.userSessionToken,
          'Content-Type': 'application/json'
        }
      };
    }
  }

  private configUserObservables() {
    this.idTokenUser = this.angularFireAuth.idToken;
    this.userAuth = this.angularFireAuth.authState.pipe(
      switchMap(user => {
        if (user && user.email) {
          this.updateUserWithAuth(user);
          this.setUserEtcObservable(user.email);
          return this.angularFirestore.doc<UserModel>(`usuario/${user.email}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  private async updateUserWithAuth(authUser: UserInfo | null) {
    if (authUser) {
      const userData: UserAuthModel = {
        uid: authUser.uid,
        email: authUser.email,
        displayName: authUser.displayName,
      };
      if (authUser.photoURL) {
        userData.photoUrl = authUser.photoURL;
      }
      const userRef = this.angularFirestore.doc(`usuario/${authUser.email}`);
      return userRef.set(userData, { merge: true }).catch(error => {
        console.error('Problemas ao alterar o status do usuário autenticado', error);
      });
    }
  }

  private setUserEtcObservable(userEmail: string) {
    if (!this.loadedOnce && userEmail) {
      this.loadedOnce = true;
      this.userService.getUserByEmail(userEmail).then((userData) => {
        console.log('userData.email', userData?.email);
      });
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfMaskHide'
})
export class CpfMaskHidePipe implements PipeTransform {
  transform(cpf: string): string {
    let maskedCpf = cpf;
    if (!maskedCpf) {
      return maskedCpf;
    } else if (cpf.length !== 11) {
      maskedCpf = cpf.replace(/\D/g, '');
    }
    return `${maskedCpf.substring(0, 3)}.${'***'}.${'***'}-${maskedCpf.substring(9, 11)}`;
  }
}

@Pipe({
  name: 'phoneMaskHide'
})
export class PhoneMaskHidePipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) {
      return phone;
    }

    let maskedPhone;
    if (phone.length === 13) {
      maskedPhone = `${phone.substring(0, 3)} (${phone.substring(3, 5)}) ${phone.substring(5, 6)}***-**${phone.substring(11)}`;
    } else if (phone.length === 14) { // Para números com 14 dígitos
      maskedPhone = `${phone.substring(0, 3)} (${phone.substring(3, 5)}) ${phone.substring(5, 6)}****-**${phone.substring(12)}`;
    } else {
      return phone;
    }

    return maskedPhone;
  }
}

@Pipe({
  name: 'emailMaskHide'
})
export class EmailMaskHidePipe implements PipeTransform {
  transform(email: string): string {
    if (!email || !email.includes('@')) {
      return email;
    }

    const [localPart, domain] = email.split('@');

    let visiblePart;
    let maskedPart;

    if (localPart.length <= 4) {
      visiblePart = localPart.substring(0, 1);
      maskedPart = '*'.repeat(localPart.length - 1);
    } else {
      visiblePart = `${localPart.substring(0, 2)}${'*'.repeat(localPart.length - 4)}${localPart.substring(localPart.length - 2)}`;
      maskedPart = '';
    }

    return `${visiblePart}${maskedPart}@${domain}`;
  }
}

<div class="loading-overlay" *ngIf="isLoading()">
  <div class='loading'>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>

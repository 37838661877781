export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC-MvDaghMtIZYNwzC4T8iZjVXEKyO3-y0',
    authDomain: 'frotanet-webapp-test.firebaseapp.com',
    databaseURL: 'https://frotanet-webapp-test.firebaseio.com',
    projectId: 'frotanet-webapp-test',
    storageBucket: 'frotanet-webapp-test.appspot.com',
    messagingSenderId: '879847091227',
    appId: '1:879847091227:web:a40bed2be094af7ce49cbe'
  },
  apiUrl: 'https://us-central1-frotanet-webapp-test.cloudfunctions.net/',
  urlCalledFunction: undefined,
  apiConhecimento: 'conhecimentoapi/',
  defaultCompanyPhoto: '../assets/images/frotanet/default_company.png',
  defaultUserPhoto: '../assets/images/frotanet/default_user.svg',
  defaultEmbPhoto: '../assets/images/frotanet/default_emb.png',
  keyEncryptUrl: 'ChavedeEncriptamentoDeUrlFrotanet',
  passwordSecretKey: '=UKS"CaM)0#~Lh}SB%4}*"]eT$P):/)Q,mG%Iu=.{K7gHnVK6^-b;2CD"3vs1"7'
};

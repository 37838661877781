import { DocumentData, SnapshotOptions } from "@angular/fire/compat/firestore";
import { QueryDocumentSnapshot } from "firebase/firestore";

export class BaseSindicalModel {

  id: string;
  name: string;

  constructor() {

    this.id = '';
    this.name = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: BaseSindicalModel): DocumentData => {

      const returnData: any = {
        id: entityData.id,
        name: entityData.name
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new BaseSindicalModel(), snapshotData);
    }
  }
}

import { NgModule, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar-next';

import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';

import { AdminComponent } from './layouts/admin/admin.component';
import { AdminHeaderComponent } from './layouts/admin/header/header.component';
import { AdminSidebarComponent } from './layouts/admin/sidebar/sidebar.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { SpinnerComponent } from './shared/spinner.component';

import lcoaleDeAt from '@angular/common/locales/pt';

import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { LoadingModule } from './shared/loading/loading.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

registerLocaleData(lcoaleDeAt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    AppBlankComponent,
    AdminComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRouting,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    LyThemeModule.setTheme('minima-light'),
    LoadingModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

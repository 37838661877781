import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { Company } from '../models/Company';
import { PropostaAntecipacaoModel } from '../models/proposta-antecipacao.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TransportadoraService {
  transportadoraCollName = 'transportadora';
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private angularFire: AngularFirestore,
  ) { }

  getTransportadoraPorId(idTransp: string): Observable<Company | undefined> {
    return this.angularFire.doc<Company>(`${this.transportadoraCollName}/${idTransp}`).valueChanges();
  }

  getTransportadorPorIdP(idTransportadora: string): Promise<Company | undefined> {
    return firstValueFrom(this.angularFire.doc<Company>(`${this.transportadoraCollName}/${idTransportadora}`).valueChanges());
  }

  updateNumeroFatura(idTransp: string, numeroFatura: number): Promise<void> {
    return this.angularFire.doc(`${this.transportadoraCollName}/${idTransp}`).update({ lastBillNumber: numeroFatura });
  }

  async createPropostaAntecipacao(propostaAntecipacao: PropostaAntecipacaoModel): Promise<void> {
    const transpRef = this.angularFire.doc<Company>(`${this.transportadoraCollName}/${propostaAntecipacao.dadosTransportadora.id}`).ref;
    return this.angularFire.firestore.runTransaction((transaction) => {
      return transaction.get(transpRef).then((documentSnapshot) => {
        const lastBillNumber = documentSnapshot.data()!.lastBillNumber + 1;
        propostaAntecipacao.dadosTitulo.numeroFatura = lastBillNumber.toString().padStart(6, '0');
        propostaAntecipacao.dadosTitulo.numeroOperacao = documentSnapshot.data()!.operationNumber;
        const prospostaRef = this.angularFire.collection('propostaantecipacao').doc<PropostaAntecipacaoModel>().ref;
        propostaAntecipacao.id = prospostaRef.id;
        propostaAntecipacao.dataCriacao = new Date();
        propostaAntecipacao.dataAlteracao = propostaAntecipacao.dataCriacao;
        propostaAntecipacao.situacao = 'cancelada';
        propostaAntecipacao.dadosTransportadora.razaoSocial = documentSnapshot.data()!.razaoSocial!;
        propostaAntecipacao.dadosTransportadora.cnpj = documentSnapshot.data()!.cnpj!;
        transaction.set(prospostaRef.withConverter(PropostaAntecipacaoModel.firestoreConverter), propostaAntecipacao, { merge: false });
        transaction.update(transpRef, { lastBillNumber: lastBillNumber });
      });
    });
  }

  async setPropostaAntecipacao(propostaAntecipacao: PropostaAntecipacaoModel): Promise<void> {
    propostaAntecipacao.dataAlteracao = new Date();
    return this.angularFire
      .doc<PropostaAntecipacaoModel>(`propostaantecipacao/${propostaAntecipacao.id}`)
      .ref.withConverter(PropostaAntecipacaoModel.firestoreConverter)
      .set(propostaAntecipacao, { merge: true });
  }

  async updatePropostaAntecipacao(propostaAntecipacaoId: string, dadosPropostaAntecipacao: any): Promise<void> {
    dadosPropostaAntecipacao.dataAlteracao = new Date();
    return this.angularFire
      .doc(`propostaantecipacao/${propostaAntecipacaoId}`)
      .update(dadosPropostaAntecipacao);
  }

  async getPropostaAntecipacao(idProposta: string) {
    const snapshot = await this.angularFire
      .doc(`propostaantecipacao/${idProposta}`)
      .ref.withConverter(PropostaAntecipacaoModel.firestoreConverter)
      .get();
    return snapshot.data();
  }

  createQuote(dadosPropostaAntecipacao: PropostaAntecipacaoModel): Promise<any> {
    return firstValueFrom(this.http.post(
      environment.apiUrl + 'creditsecurityapi/create-quote',
      { data: dadosPropostaAntecipacao },
      this.authService.getHeader()
    ));
  }

  rejectQuote(dadosPropostaAntecipacao: PropostaAntecipacaoModel): Promise<any> {
    return firstValueFrom(this.http.post(
      environment.apiUrl + 'creditsecurityapi/reject-quote',
      { data: dadosPropostaAntecipacao },
      this.authService.getHeader()
    ));
  }

  activateCover(dadosPropostaAntecipacao: PropostaAntecipacaoModel): Promise<any> {
    return firstValueFrom(this.http.post(
      environment.apiUrl + 'creditsecurityapi/activate-cover',
      { data: dadosPropostaAntecipacao },
      this.authService.getHeader()
    ));
  }

  uploadCustomerCert(transpId: string, customerId: number, cert: string, pwd: string): Promise<any> {
    return firstValueFrom(this.http.put(
      environment.apiUrl + 'focusnfeapi/upload-customer-cert',
      { data: { transpId: transpId, customerId: customerId, cert: cert, pwd: pwd } },
      this.authService.getHeader()
    ));
  }
}

import { AbstractControl } from "@angular/forms";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { isMoment } from "moment";
import { StatusOrdemColeta, TipoEncerramento } from "../models/constants";
import { AgenciamentoInteresseModel } from "../models/ofertas-model";

export class UtilHandler {

  public static formatPhone() {
    return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  public static formatCnh() {
    return [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  }

  public static formatPlaca() {
    return [/[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/, /[0-9A-Z]/];
  }

  public static getFormattedPrice(price: number) {
    if (!price || isNaN(price)) {
      return 'R$ 0,00';
    } else {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
    }
  }

  public static getFormattedNumber(value: number, digits = 2) {
    if (!value || isNaN(value)) {
      return '0,00';
    } else {
      return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: digits }).format(value);
    }
  }

  public static formatTelefone(this: string) {
    if (this && this.length < 15) {
      return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  public static convertTimestampToDate(timestamp: any): Date | null {
    if (timestamp) {
      return (timestamp as firebase.firestore.Timestamp).toDate();
    } else {
      return null;
    }
  }

  static convertToDateType(valueToConvert: Date | firebase.firestore.Timestamp | null | undefined): Date | null {
    let dateOrNull: Date | null = null;
    if (valueToConvert) {
      if (valueToConvert instanceof Date || typeof valueToConvert === 'string') {
        dateOrNull = valueToConvert;
      } else {
        dateOrNull = (valueToConvert as firebase.firestore.Timestamp).toDate();
      }
    }
    return dateOrNull;
  }

  public static getRegraNegocioPadrao(uuid: string) {
    const dateTimeNow = new Date();
    return {
      uid: uuid,
      banco: {
        id: '',
        codigo: '',
        razaoSocial: '',
        nomeFantasia: '',
        cnpj: '00.000.000/0000-00',
        nome: ''
      },
      idEmbarcador: '',
      embarcador: {
        nomeFantasia: '',
        cnpj: '',
        razaoSocial: '',
        id: ''
      },
      txDesagio: 10,
      dataInicialValidadeTaxa: dateTimeNow,
      dtValidade: 365,
      dataFinalValidadeTaxa: new Date(new Date().setDate(dateTimeNow.getDate() + 365)),
      tipo: 'fixo',
      prazoTotalFinanciamento: 90,
      diasPosFechamento: 0,
      ativo: false,
      percentualSobreFrete: 1,
      valorLimite: 10,
      prazoPagamentoFrete: {
        tipoPrazoPagamento: 'tipoRelativo',
        valorQuantidadeDias: 45,
        tipoRelacao: {
          id: 'pos_entrega',
          name: 'Pós Entrega'
        },
        diaDoMes: {
          id: 0,
          name: 'Último dia do mês'
        }
      },
      diaFechamento: 0,
      fechamentoCiclo: 0,
      dtCadastro: dateTimeNow,
      antecipacaoSemAprovacao: true,
      sellerId: '0',
      buyerId: '0',
      valorJurosMora: 0,
      valorMultaMora: 0,
      indiceCorrecao: ''
    };
  }

  public static getAllUFs(): string[] {
    return ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  }

  public static formatCep() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  public static formatDocumento(documento: string) {
    if (documento.length < 15) {
      return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    } else if (documento.length < 19) {
      return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    } else {
      return [];
    }
  }

  public static convertFormControlToDate(formControl: AbstractControl) {
    let dataHora: Date | null = null;
    if (formControl.value) {
      if (isMoment(formControl.value)) {
        dataHora = new Date(formControl.value.toString());
      } else {
        dataHora = formControl.value;
      }
    }
    return dataHora;
  }

  public static resolveStatusOC(dadosOferta: AgenciamentoInteresseModel) {
    switch (dadosOferta.situacao) {
      case StatusOrdemColeta.Ativa:
        return 'Aguardando TAC Iniciar Coleta';
      case StatusOrdemColeta.ViagemIniciada:
        return 'TAC: Em Viagem para Coleta';
      case StatusOrdemColeta.ViagemFinalizadaSucesso:
        return 'TAC: Chegou para Carregamento';
      case StatusOrdemColeta.ViagemFinalizadaErro:
        return 'TAC: OC não honrada pela ETC';
      case StatusOrdemColeta.ViagemCanceladaTac:
        return 'TAC: Cancelou OC';
      case StatusOrdemColeta.ViagemNaoRealizadaTac:
        return 'ETC: TAC não compareceu';
      case StatusOrdemColeta.OrdemColetaCanceladaEtc:
        return 'ETC: Cancelou OC';
      case StatusOrdemColeta.OrdemColetaEncerradaEtc:
        switch (dadosOferta.dadosEncerramento.tipoEncerramento) {
          case TipoEncerramento.EncerradaSucesso:
            const successStatus = "TAC: Chegou para Carregamento";
            if (dadosOferta.dadosEncerramento.descricaoMotivo) {
              return `${successStatus}\nETC: "${dadosOferta.dadosEncerramento.descricaoMotivo}"`;
            } else if (dadosOferta.dadosEncerramento.nomeMotivo) {
              return `${successStatus}\nETC: ${dadosOferta.dadosEncerramento.nomeMotivo}`;
            } else {
              return successStatus;
            }
          case TipoEncerramento.EncerradaErro:
            const errorStatus = `TAC: OC não honrada pela ETC`;
            if (dadosOferta.dadosEncerramento.descricaoMotivo) {
              return `${errorStatus}\nETC: "${dadosOferta.dadosEncerramento.descricaoMotivo}"`;
            } else if (dadosOferta.dadosEncerramento.nomeMotivo) {
              return `${errorStatus}\nETC: ${dadosOferta.dadosEncerramento.nomeMotivo}`;
            } else {
              return errorStatus;
            }
          case TipoEncerramento.CanceladoPeloTac:
            return 'TAC: Cancelou OC';
          case TipoEncerramento.CanceladoPelaEtc:
            return 'ETC: Cancelou OC';
          default:
            return 'UNDEFINED STATUS';
        }
      default:
        return 'INDEFINIDA';
    }
  }

  public static capsFirstLetter(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

export function combineStringArrays(arrays: string[][]): string[] {
  // Base case: if there are no arrays left, return an array with an empty string
  if (arrays.length === 0) {
    return [''];
  }
  // Recursive case:
  // 1. Take the first array
  const firstArray = arrays[0];
  // 2. Recursively combine the rest of the arrays
  const combinationsOfRest = combineStringArrays(arrays.slice(1));
  // 3. For each string in the first array, prepend it to each combination of the rest of the arrays
  let combinations: string[] = [];
  for (let str of firstArray) {
    for (let combination of combinationsOfRest) {
      combinations.push(combination ? `${str} ${combination}`.trim() : str);
    }
  }
  // Include combinations of the rest without prepending
  combinations = combinations.concat(combinationsOfRest);
  return combinations;
}

export function getCombinationsFilter(numberOfVehicleAxles: string[], typesOfVehicleBodies: string[], certifications: string): string[] {
  let combinations: string[] = [];
  for (let axles of numberOfVehicleAxles) {
    for (let body of typesOfVehicleBodies) {
      const combination = `${axles} ${body} ${certifications}`;
      combinations.push(combination.trim());
    }
  }
  return combinations;
}

export function generateArrayVehicleAxles(numberOfAxles: number): string[] {
  const allowedNumbers = [2, 3, 4, 5, 6, 7, 8, 9, 11];
  return allowedNumbers.filter(n => n >= numberOfAxles).map(n => n.toString());
}

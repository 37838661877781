<div fxLayout="row wrap" class="frota-net">
  <div fxFlex="100" class='title'>
    Dados do Usuário - Troca de senha
  </div>

  <div fxFlex='100'>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="formAlteraSenha" class="basic-form">



          <div fxLayout="row wrap" class='form-fields'>

            <div class="totalY1" fxFlex='100' fxLayout="row wrap">
              <div class="containerX" fxFlex='50'>
                <div class="titleLine">Senha atual</div>
                <div class="description">Digite sua senha atual.</div>
              </div>
              <div class="containerX2" fxFlex='50'>
                <mat-form-field appearance="fill">
                  <input matInput type="password" formControlName="senhaAtualCtrl" (input)="changeData()">
                  <mat-error *ngIf="formAlteraSenha.controls['senhaAtualCtrl'].hasError('required')">Senha
                    atual</mat-error>
                  <mat-error *ngIf="formAlteraSenha.controls['senhaAtualCtrl'].hasError('minlength')">Senha
                    deve conter ao menos 8 caracteres</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="totalY2" fxFlex='100' fxLayout="row wrap">
              <div class="containerX" fxFlex='50'>
                <div class="titleLine">Nova senha</div>
                <div class="description">
                  Digite sua nova senha com 8 digitos.
                </div>
              </div>
              <div class="containerX2" fxFlex='50'>
                <mat-form-field appearance="fill">
                  <input matInput type="password" formControlName="novaSenhaCtrl" (input)="changeData()">
                  <mat-error *ngIf="formAlteraSenha.controls['novaSenhaCtrl'].hasError('required')">Nova senha
                  </mat-error>
                  <mat-error *ngIf="formAlteraSenha.controls['novaSenhaCtrl'].hasError('minlength')">Senha deve conter
                    ao menos 8 caracteres</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="totalY3" fxLayout="row wrap" fxFlex='100'>
              <div class="containerX" fxFlex='50'>
                <div class="titleLine">Confirmar Nova Senha</div>
                <div class="description">
                  Digite sua senha para confirmar.
                </div>
              </div>
              <div class="containerX2" fxFlex='50'>
                <mat-form-field appearance="fill">
                  <input matInput type="password" formControlName="confirmaSenhaCtrl" (input)="changeData()">
                  <mat-error *ngIf="formAlteraSenha.controls['confirmaSenhaCtrl'].hasError('required')">Confirmar a nova
                    senha</mat-error>
                  <mat-error *ngIf="formAlteraSenha.controls['confirmaSenhaCtrl'].hasError('mismatch')">Confirmação da
                    nova senha não confere.</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="containerX3" fxflex="100">
              <p style="color: #EC4115 !important; font-size: 11px !important;">
                Atenção: ao clicar no botão Trocar senha a sua atual sessão no FROTANET
                será finalizada e você precisará fazer novo login com a nova senha criada.
              </p>
            </div>

          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex='60'>
  </div>
  <div fxFlex="20" class='p-r-10'>
    <button mat-raised-button color="primary" class="btn-block m-t-20" (click)='back()'>
      CANCELAR</button>
  </div>
  <div fxFlex="20" class='p-r-10'>
    <button mat-raised-button color="primary" class="btn-block m-t-20" (click)='verificarSenhas()'>SALVAR</button>
  </div>
</div>
<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark }">

  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
        <!-- Logo icon -->
        <b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img src="../../../assets/images/frotanet/logo-frotanet-dashboard.svg" alt="homepage" class="dark-logo">
          <!-- Light Logo icon -->
          <img src="../../../assets/images/frotanet/logo-frotanet-dashboard.svg" height="18.69px" width="148.44px" alt="homepage" class="light-logo">
        </b>
        <!--End Logo icon -->
      </a>
    </div>
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-admin-header></app-admin-header>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs admin-background" [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false" [perfectScrollbar]="config">

      <app-admin-sidebar></app-admin-sidebar>

    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list>

          <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
          <mat-list-item>
            <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
          </mat-list-item>
          <!--<mat-list-item>
<mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
</mat-list-item>    -->
          <mat-divider></mat-divider>
          <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
              Green</mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">
      <!-- ============================================================== -->
      <!-- Page container - style you can find in pages.scss -->
      <!-- ============================================================== -->
      <div class="page-content">

        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>

      </div>

    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<div class="menu-sidebar">

  <mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getAdminMenuItems(accessType)"
      routerLinkActive="selected" [isExpanded]="menuitem.name === rootMenuSeletected ? true : false">
      <a appAccordionToggle class="no-border selected" href="javascript:;" *ngIf="menuitem.type === 'sub'"
        style="cursor: default !important;" [ngClass]="{'access-denied': menuitem.isDenied}">
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
      </a>
      <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
        <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
          <a (click)="redirectMenu(moduloName, childitem.state, null, null)"
            *ngIf="childitem.type === 'link' && childitem.subState === undefined" class="relative"
            routerLinkActive="selected"
            [ngClass]="{'bg-color' : childitem.markItem, 'access-denied': childitem.state === 'access-denied'}">
            {{ childitem.name }}
          </a>
        </mat-list-item>
        <mat-list-item *ngFor="let childitem of menuitem.children">
          <a href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" class="selected"
            style="cursor: default !important;" [ngClass]="{'access-denied': menuitem.isDenied}">
            <span>{{ childitem.name }}</span>
            <span fxFlex></span>
          </a>
          <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
            <mat-list-item *ngFor="let subchild of childitem.subchildren" routerLinkActive="selected">
              <a (click)="redirectMenu(moduloName, menuitem.state, childitem.state, subchild.state)"
                routerLinkActive="selected" class="relative"
                [ngClass]="{'bg-color' : subchild.markItem, 'access-denied': subchild.state === 'access-denied'}">
                {{ subchild.name }}
              </a>
            </mat-list-item>
          </mat-nav-list>
        </mat-list-item>
        <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
          <a (click)="redirectMenu(moduloName, childitem.subState, childitem.state, null)"
            *ngIf="childitem.type === 'link' && childitem.subState !== undefined" class="relative"
            routerLinkActive="selected">
            {{ childitem.name }}
          </a>
        </mat-list-item>
        <mat-list-item *ngFor="let childitem of menuitem.children" style='height:0px;'>
          <a (click)="redirectMenu(moduloName, childitem.state, null, null)"
            *ngIf="childitem.type === 'hidden' && childitem.subState === undefined" class="relative"
            routerLinkActive="">
          </a>
        </mat-list-item>
        <mat-list-item *ngFor="let childitem of menuitem.children" style='height:0px;'>
          <a (click)="redirectMenu(moduloName, childitem.subState, childitem.state, null)"
            *ngIf="childitem.type === 'hidden' && childitem.subState !== undefined" class="relative"
            routerLinkActive="">
          </a>
        </mat-list-item>
      </mat-nav-list>

      <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
        <span>{{ menuitem.name }}</span>
      </div>
    </mat-list-item>
  </mat-nav-list>

  <div class="div-fetrabens" *ngIf="userEtcData?.dadosControleAcesso?.agenciamentoIndicado && userEtcData?.dadosControleAcesso?.agenciamentoIndicado?.value">
    <img class="img-fetrabens" src="../../../../assets/images/logo-fetrabens-branco-transparente.png">
  </div>
</div>

import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp } from '@angular/fire/firestore';
import { UtilHandler } from '../../core/util.handler';
import { GeneralPhotoModel } from '../general-photo';
import { CertificacoesModel, CnhInfoModel, RntrcInfoModel, TipoCarroceriaModel, TipoVeiculoModel, TipoVeiculoTacModel } from "../usuario-app.model";
import { ToxicologicalExamModel } from './toxicological-exam.model';
import { BaseSindicalModel } from './base-sindical.model';
import { TipoCnpjModel } from './motorista-cnpj-info.model';

export class MotoristaModel {
  uid: string;
  name: string;
  cpf: string;
  cnpj: string;
  tipoCnpj: TipoCnpjModel;
  email: string;
  phone: string;
  photo: string;
  fotoPerfil: GeneralPhotoModel;
  isGerenciarViagem: boolean;
  lastStatusUpdate?: Date;
  lastGetUserData?: Date;
  status?: string;
  souAutonomo: boolean;
  souAgregado: boolean;
  dataNascimento: Timestamp | Date | null;
  baseSindical: BaseSindicalModel;
  cnhInfo: CnhInfoModel;
  rntrcInfo: RntrcInfoModel;
  listaCertificacoes: CertificacoesModel[];
  listaCertificacoesIds: String[];
  listaCertificacoesFotos: GeneralPhotoModel[];
  listaVeiculos: TipoVeiculoModel[];
  listaVeiculosIds: String[];
  listaVeiculosTac: TipoVeiculoTacModel[];
  listaVeiculosTacIds: String[];
  listaCarrocerias: TipoCarroceriaModel[];
  listaCarroceriasIds: String[];
  endereco: EnderecoModel;
  createdAt!: Date;
  exameToxicologico: ToxicologicalExamModel;
  certificacao: CertificacaoModel;

  constructor() {
    this.uid = '';
    this.name = '';
    this.cpf = '';
    this.cnpj = '';
    this.tipoCnpj = new TipoCnpjModel();
    this.email = '';
    this.phone = '';
    this.photo = '';
    this.fotoPerfil = new GeneralPhotoModel();
    this.isGerenciarViagem = false;
    this.souAutonomo = false;
    this.souAgregado = false;
    this.dataNascimento = null;
    this.baseSindical = new BaseSindicalModel();
    this.cnhInfo = new CnhInfoModel();
    this.rntrcInfo = new RntrcInfoModel();
    this.listaCertificacoes = [];
    this.listaCertificacoesIds = [];
    this.listaVeiculos = [];
    this.listaVeiculosIds = [];
    this.listaVeiculosTac = [];
    this.listaVeiculosTacIds = [];
    this.listaCarrocerias = [];
    this.listaCarroceriasIds = [];
    this.endereco = new EnderecoModel();
    this.listaCertificacoesFotos = [];
    this.exameToxicologico = new ToxicologicalExamModel();
    this.certificacao = new CertificacaoModel();
  }

  static firestoreConverter: FirestoreDataConverter<MotoristaModel> = {
    toFirestore: (entityData: MotoristaModel): DocumentData => {

      const returnData: any = {
        name: entityData.name,
        cpf: entityData.cpf,
        cnpj: entityData.cnpj,
        tipoCnpj: TipoCnpjModel.firestoreConverter.toFirestore(entityData.tipoCnpj),
        phone: entityData.phone,
        email: entityData.email,
        souAutonomo: entityData.souAutonomo,
        souAgregado: entityData.souAgregado,
        dataNascimento: entityData.dataNascimento,
        baseSindical: BaseSindicalModel.firestoreConverter.toFirestore(entityData.baseSindical),
        endereco: EnderecoModel.firestoreConverter.toFirestore(entityData.endereco),
        fotoPerfil: GeneralPhotoModel.firestoreConverter.toFirestore(entityData.fotoPerfil),
        rntrcInfo: RntrcInfoModel.firestoreConverter.toFirestore(entityData.rntrcInfo),
        cnhInfo: CnhInfoModel.firestoreConverter.toFirestore(entityData.cnhInfo),
        exameToxicologico: ToxicologicalExamModel.firestoreConverter.toFirestore(entityData.exameToxicologico),
        certificacao: CertificacaoModel.firestoreConverter.toFirestore(entityData.certificacao),
        listaCertificacoesFotos: entityData.listaCertificacoesFotos,
        listaCertificacoes: entityData.listaCertificacoes,
        listaVeiculosTac: entityData.listaVeiculosTac.map((item) => TipoVeiculoTacModel.firestoreConverter.toFirestore(item)),
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      snapshotData["createdAt"] = UtilHandler.convertToDateType(snapshotData["createdAt"]);
      snapshotData["cnhInfo"]["dataValidade"] = UtilHandler.convertToDateType(snapshotData["cnhInfo"]["dataValidade"]);
      return Object.assign(new MotoristaModel(), snapshotData);
    }
  }
}

export class EnderecoModel {
  cep: string | null;
  logradouro: string | null;
  numero: string | null;
  bairro: string | null;
  cidade: string | null;
  estado: string | null;
  uf: string | null;
  complemento: string;

  constructor() {
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.bairro = '';
    this.cidade = '';
    this.estado = '';
    this.uf = '';
    this.complemento = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: EnderecoModel): DocumentData => {
      const returnData: any = {};
      returnData.cep = entityData.cep;
      returnData.logradouro = entityData.logradouro;
      returnData.numero = entityData.numero;
      returnData.bairro = entityData.bairro;
      returnData.cidade = entityData.cidade;
      if (entityData.estado) returnData.estado = entityData.estado;
      returnData.uf = entityData.uf;
      if (entityData.complemento) returnData.complemento = entityData.complemento;
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      return Object.assign(new EnderecoModel(), snapshot.data(options));
    }
  }
}

export class CertificacaoModel {

  name: string;
  id: string;
  listaCertificacoes: CertificacoesModel[];
  listaCertificacoesFotos: GeneralPhotoModel[];

  constructor() {
    this.name = '';
    this.id = 'sem-certificacao';
    this.listaCertificacoes = [];
    this.listaCertificacoesFotos = [];
  }

  static firestoreConverter = {
    toFirestore: (entityData: CertificacaoModel): DocumentData => {

      const returnData: any = {
        id: entityData.id,
        name: entityData.name,
        listaCertificacoes: entityData.listaCertificacoes,
        listaCertificacoesFotos: entityData.listaCertificacoesFotos
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new ToxicologicalExamModel(), snapshotData);
    }
  }
}

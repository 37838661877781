import { ChildrenItems } from "src/app/shared/menu-items/menu-items.model";

export const adminChildren: ChildrenItems[] = [
  { state: 'motorista-listar', name: 'Motorista', type: 'link' },
  { state: 'transportadora-listar', name: 'Transportadoras', type: 'link' },
]

export const ofertasFreteChildren: ChildrenItems[] = [
  { state: 'ofertas-frete-ativas', name: 'Ofertas Ativas', type: 'link' },
  { state: 'ofertas-frete-com-match', name: 'Ofertas com Match', type: 'link' },
  { state: 'ofertas-frete-historico', name: 'Histórico', type: 'link' },
]

export const ofertasAgregamentoChildren: ChildrenItems[] = [
  { state: 'ofertas-agregamento-ativas', name: 'Ofertas Ativas', type: 'link' },
  { state: 'tacs-agregados', name: 'TACs Agregados', type: 'link' },
  { state: 'ofertas-agregamento-historico', name: 'Histórico', type: 'link' },
]

export const coletasChildren: ChildrenItems[] = [
  { state: 'ordens-coleta-listar', name: 'Ordens de Coleta', type: 'link' },
  { state: 'historico-ordem-coleta-listar', name: 'Histórico de Coletas', type: 'link' },
]

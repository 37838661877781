import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { EmptyRouteComponent } from './core/route/empty-route.component';
import { EmptyRouteResolver } from './core/route/empty-route.resolver';

const routes: Routes = [
  {
    path: '',
    component: EmptyRouteComponent,
    resolve: { redirect: EmptyRouteResolver },
    pathMatch: 'full'
  },
  {
    path: 'admin-callcenter',
    component: AdminComponent,
    loadChildren: () => import('./admin-callcenter/admin-callcenter.module').then(m => m.AdminCallCenterModule),
  },
  {
    path: 'admin-etc',
    component: AdminComponent,
    loadChildren: () => import('./admin-etc/admin-etc.module').then(m => m.AdminEtcModule),
  },
  {
    path: 'admin-frotanet',
    component: AdminComponent,
    loadChildren: () => import('./admin-frotanet/admin-frotanet.module').then(m => m.AdminFrotanetModule),
  },
  {
    path: 'authentication',
    component: AppBlankComponent,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'external',
    component: AppBlankComponent,
    loadChildren: () => import('./external/external.module').then(m => m.ExternalModule),
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [EmptyRouteResolver],
})
export class AppRouting { }

import { LyTheme2, ThemeVariables } from '@alyle/ui';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/auth/auth.service';
import { UserModel } from './models/UserModel';
import { Platform } from '@angular/cdk/platform';

const STYLES = (theme: ThemeVariables) => ({
  '@global': {
    body: {
      backgroundColor: theme.background.default,
      color: theme.text.default,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      direction: theme.direction
    }
  }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  loggedUser!: UserModel;
  subscription: Subscription;
  isCompatibleBrowser = false;
  readonly classes = this.theme.addStyleSheet(STYLES);

  constructor(
    private theme: LyTheme2,
    private authService: AuthService,
    public platform: Platform,
  ) {
    if (this.platform.BLINK || this.platform.SAFARI || this.platform.EDGE) {
      this.isCompatibleBrowser = true;
    }
    this.subscription = new Subscription();
    this.subscription.add(this.authService.userAuth.subscribe(userData => {
      if (userData) {
        this.authService.userSessionData = userData;
      }
    }));
    this.subscription.add(this.authService.idTokenUser.subscribe((userToken) => {
      if (userToken) {
        this.authService.userSessionToken = userToken;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (environment.production) {
      this.authService.appClosed();
    }
  }

}

import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "@angular/fire/firestore";

export class GeneralPhotoModel {
  id: string;
  fileBytes: string;
  fileName: string;
  fileExtension: string;
  filePath: string;

  constructor() {
    this.id = "";
    this.fileBytes = "";
    this.fileName = "";
    this.fileExtension = "";
    this.filePath = "";
  }

  getFirebaseObject(): any {
    return {
      id: this.id,
      fileBytes: this.fileBytes,
      fileName: this.fileName,
      fileExtension: this.fileExtension,
      filePath: this.filePath
    };
  }

  static firestoreConverter = {
    toFirestore: (entityData: GeneralPhotoModel): DocumentData => {
      const returnData: any = {};
      returnData.id = entityData.id;
      returnData.fileName = entityData.fileName;
      returnData.fileExtension = entityData.fileExtension;
      returnData.filePath = entityData.filePath;
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new GeneralPhotoModel(), snapshotData);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CheckboxItem } from './checkbox-item.interface';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() items: CheckboxItem[] = [];
  @Input() form: FormGroup = this.fb.group({
    checkboxGroup: this.fb.control([])
  });
  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form.get('checkboxGroup')?.setValue(this.items.map(() => false));
  }

  onCheckboxChange(event: MatCheckboxChange, index: number): void {

    const checkboxGroup = this.form.get('checkboxGroup');

    if (checkboxGroup) {

      const values = checkboxGroup.value as boolean[];
      values[index] = event.checked;
      checkboxGroup.setValue(values);
    }
  }

  getOrientation() {

    if (this.orientation === 'horizontal') {

      return { display: 'flex' };
    }

    return { display: 'grid' };
  }
}

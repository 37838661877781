import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number, currencyCode: string = 'BRL', symbolDisplay: string = 'symbol', number_format: string = '1.2-2'): string {
    if (value) {
      const currencyPipe = new CurrencyPipe('pt-BR');
      const newValue = currencyPipe.transform(value, currencyCode, symbolDisplay, number_format) ?? '';
      return newValue;
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'currencyFormatZ'
})
export class CurrencyFormatZPipe implements PipeTransform {
  transform(value: number, noValue: string = '- -', currencyCode: string = 'BRL', symbolDisplay: string = 'symbol', number_format: string = '1.2-2'): string {
    if (value) {
      const currencyPipe = new CurrencyPipe('pt-BR');
      const newValue = currencyPipe.transform(value, currencyCode, symbolDisplay, number_format) ?? '';
      return newValue;
    } else {
      return noValue;
    }
  }
}

@Pipe({
  name: 'currencyFormatI'
})
export class CurrencyFormatIPipe implements PipeTransform {
  transform(value: number, currencyCode: string = 'BRL', symbolDisplay: string = 'symbol', number_format: string = '1.2-2'): string {
    if (value) {
      const currencyPipe = new CurrencyPipe('pt-BR');
      const newValue = currencyPipe.transform(value, currencyCode, symbolDisplay, number_format) ?? '';
      return newValue.substring(0, newValue.lastIndexOf(','));
    } else {
      return '';
    }
  }
}

import {
  Directive,
  AfterContentChecked
} from '@angular/core';
import { Router } from '@angular/router';
import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
  selector: '[appAccordion]'
})
export class AccordionDirective implements AfterContentChecked {
  protected navlinks: Array<AccordionLinkDirective> = [];

  closeOtherLinks(selectedLink: AccordionLinkDirective): void {
    this.navlinks.forEach((link: AccordionLinkDirective) => {
      if (link !== selectedLink) {
        link.selected = false;
      }
    });
  }

  addLink(link: AccordionLinkDirective): void {
    this.navlinks.push(link);
    if (this.navlinks && this.navlinks.length > 0 && link.isExpanded) {
      link.selected = true;
    }
  }

  removeGroup(link: AccordionLinkDirective): void {
    const index = this.navlinks.indexOf(link);
    if (index !== -1) {
      this.navlinks.splice(index, 1);
    }
  }

  checkOpenLinks() {
    this.navlinks.forEach((link: AccordionLinkDirective) => {
      if (link.isExpanded) {
        link.selected = true;
        this.closeOtherLinks(link);
      }
    });
  }

  ngAfterContentChecked(): void {
    // this.router.events
    //   .filter(event => event instanceof NavigationEnd)
    //   .subscribe(e => this.checkOpenLinks());
    console.debug('ngAfterContentChecked');
  }

  constructor(private router: Router) {
    // setTimeout(() => this.checkOpenLinks());
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserModel } from '../models/UserModel';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userCollName = 'usuario';
  constructor(
    private angularFire: AngularFirestore,
  ) { }

  async validateFinancialPwd(userId: string, financialPwd: string): Promise<boolean> {
    const snapshot = await this.angularFire.doc<UserModel>(`${this.userCollName}/${userId}`).ref.get();
    if (snapshot.exists && snapshot.data()?.financeiro && snapshot.data()?.financeiro?.senha === financialPwd) {
      return true;
    } else {
      return false;
    }
  }

  async getUserByEmail(userEmail: string): Promise<UserModel | null> {
    const user = await firstValueFrom(this.angularFire.doc<UserModel>(`usuario/${userEmail}`).get());
    if (user.exists) {
      return user.data()!;
    } else {
      return null;
    }
  }
}

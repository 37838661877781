import { QueryDocumentSnapshot, Timestamp } from "firebase/firestore";
import { GeneralPhotoModel } from "../general-photo";
import { DocumentData, SnapshotOptions } from "@angular/fire/compat/firestore";

export class ToxicologicalExamModel {

  dataVencimento: Timestamp | Date | null;
  id: string;
  listaFotos: Array<GeneralPhotoModel>;
  name: string;

  constructor() {
    this.dataVencimento = null;
    this.id = '';
    this.listaFotos = [];
    this.name = '';

  }
  static firestoreConverter = {
    toFirestore: (entityData: ToxicologicalExamModel): DocumentData => {

      const returnData: any = {
        dataVencimento: entityData.dataVencimento,
        id: entityData.id,
        listaFotos: entityData.listaFotos,
        name: entityData.name
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new ToxicologicalExamModel(), snapshotData);
    }
  }
}

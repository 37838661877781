import { Component, Input } from '@angular/core';

import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input()
  uid!: string;

  constructor(
    private loadingService: LoadingService
  ) { }

  isLoading(): boolean {
    return this.loadingService.isLoading(this.uid);
  }

}

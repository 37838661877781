import { ModuloAcessoModel } from "./Company";
import { GenericStructure } from "./GenericStructure";

export class Constants {
  public static get getMotivosCancelamento(): GenericStructure[] {
    const motivosCancelamento: GenericStructure[] = [];
    motivosCancelamento.push({ id: 'veiculoSemCondicoes', name: 'Veículo do TAC selecionado não apresenta condições para transporte (mecânica, pneus, etc)' });
    motivosCancelamento.push({ id: 'tacDocumentoInadequado', name: 'Veículo do TAC selecionado com documentação inadequada' });
    motivosCancelamento.push({ id: 'veiculoDiferenteCadastro', name: 'Veículo do TAC selecionado diferente do informado no cadastro' });
    motivosCancelamento.push({ id: 'tacDiferenteCadastro', name: 'TAC selecionado diferente do TAC que compareceu para a coleta' });
    motivosCancelamento.push({ id: 'veiculoDocumentacaoInadequada', name: 'TAC selecionado com documentação inadequada' });
    motivosCancelamento.push({ id: 'outro', name: 'Outro motivo' });
    return motivosCancelamento;
  }

  public static get getMotivosCancelamentoEtc(): GenericStructure[] {
    const motivosCancelamento: GenericStructure[] = [];
    motivosCancelamento.push({ id: 'embarcadorCancelouServico', name: 'Embarcador cancelou o serviço de transporte' });
    motivosCancelamento.push({ id: 'transportadoraCancelouOC', name: 'Ordem de Coleta cancelada pela Transportadora' });
    motivosCancelamento.push({ id: 'tacNaoCompareceuParaCarregamento', name: 'TAC não compareceu para carregamento' });
    motivosCancelamento.push({ id: 'outro', name: 'Outro motivo' });
    return motivosCancelamento;
  }

  public static get getMotivosEncerramentoEtc(): GenericStructure[] {
    const motivosCancelamento: GenericStructure[] = [];
    motivosCancelamento.push({ id: 'viagem-cancelada-tac', name: 'Cancelamento confirmado pela ETC.' });
    motivosCancelamento.push({ id: 'viagem-cancelada-tac-republicada', name: 'Cancelamento confirmado e republicada pela ETC.' });
    return motivosCancelamento;
  }

  static get moduloAdministrativo(): ModuloAcessoModel {
    return { name: 'Administrativo', value: true };
  }
  static get moduloAgenciamento(): ModuloAcessoModel {
    return { name: 'Agenciamento', value: false };
  }
  static get moduloAgenciamentoIndicado(): ModuloAcessoModel {
    return { name: 'Indicado por FETRABENS', value: false };
  }
  static get moduloTragego(): ModuloAcessoModel {
    return { name: 'Tráfego', value: false };
  }
  static get moduloFinanceiro(): ModuloAcessoModel {
    return { name: 'Financeiro', value: false };
  }
}

export enum TipoEncerramento {
  CanceladoPelaEtc = 'cancelada-pela-etc',
  CanceladoPeloTac = 'cancelada-pelo-tac',
  EncerradaSucesso = 'encerrada-sucesso',
  EncerradaErro = 'encerrada-erro',
}

export enum StatusOrdemColeta {
  Ativa = 'ativa',
  ViagemIniciada = 'viagem-coleta-iniciada',
  ViagemFinalizadaSucesso = 'viagem-coleta-finalizada-sucesso',
  ViagemFinalizadaErro = 'viagem-coleta-finalizada-erro',
  ViagemCanceladaTac = 'viagem-coleta-cancelada-tac',
  ViagemNaoRealizadaTac = 'viagem-coleta-nao-realizada-tac',
  OrdemColetaCanceladaEtc = 'ordem-coleta-cancelada-etc',
  OrdemColetaEncerradaEtc = 'ordem-coleta-encerrada-etc',
}

export type AccessType = AccessTypeEnum;

export enum AccessTypeEnum {
  frotanetadmin = "frotanetadmin",
  transportador = "transportador",
  callcenteradmin = "callcenteradmin",
  none = "none",
  embarcador = "embarcador",
  banco = "banco",
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.scss']
})
export class SimpleInputComponent {

  @Input() placeholder: string = '';
  @Input() control: AbstractControl | null = null;
  @Input() formControlName: string = '';
  @Input() width: string = '';

  get formControl(): FormControl | null {
    return this.control instanceof FormControl ? this.control as FormControl : null;
  }
}

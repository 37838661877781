import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar-next';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserModel } from 'src/app/models/UserModel';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import { ChildrenItems, Menu } from 'src/app/shared/menu-items/menu-items.model';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';
import { TransportadoraService } from 'src/app/services/transportadora.service';
import { Company } from 'src/app/models/Company';
import {
  adminChildren,
  ofertasFreteChildren,
  antecipacoesSubChildren,
  creditoChildren,
  gestaoFretesChildren,
  ofertasAgregamentoChildren,
  coletasChildren
} from 'src/app/core/route/admin-etc.routes';
import { AccessType, AccessTypeEnum } from 'src/app/models/constants';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class AdminSidebarComponent implements OnDestroy {
  loggedUser!: UserModel;
  subscription: Subscription;
  userEtcData: Company | undefined;
  isLoadedUser = false;
  accessType: AccessType = AccessTypeEnum.none;
  moduloName = '';
  listMenus!: Menu[];
  urlAtual: string = '';
  refreshPage = true;
  rootMenuSeletected = '';

  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  status = false;

  clickEvent() {
    this.status = !this.status;
  }

  subclickEvent() {
    this.status = true;
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private router: Router,
    private transpService: TransportadoraService,
  ) {
    this.subscription = new Subscription();
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.urlAtual = this.router.url;
        }
      })
    );
    this.mobileQuery = media.matchMedia('(min-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.subscription.add(
      this.authService.userAuth.subscribe(user => {
        if (!this.isLoadedUser && user && user.accessType) {
          this.isLoadedUser = true;
          this.loggedUser = user;
          this.menuItems.resetMenus();
          this.accessType = user.accessType;
          this.listMenus = this.menuItems.getAdminMenuItems(this.accessType);
          this.addMarkItemToMenu(this.urlAtual.split('/')[2]);

          if (this.loggedUser.empresa && this.loggedUser.empresa.id) {
            this.subscription.add(
              this.transpService.getTransportadoraPorId(this.loggedUser.empresa.id).subscribe(etcData => {
                this.userEtcData = etcData;
                this.prepareGeneralMenu();
              })
            );
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  prepareGeneralMenu() {
    if (this.accessType === AccessTypeEnum.transportador) {
      this.moduloName = 'admin-etc';
      if (this.userEtcData && this.userEtcData.dadosControleAcesso) {
        if (!this.userEtcData.dadosControleAcesso.administrativo.value) {
          this.disableMenu('ADMINISTRATIVO');
        } else {
          this.enableMenu('ADMINISTRATIVO', adminChildren);
        }
        if (!this.userEtcData.dadosControleAcesso.agenciamento.value) {
          this.disableMenu('OFERTAS DE FRETE');
          this.disableMenu('OFERTAS DE AGREGAMENTO');
          this.disableMenu('COLETAS');
        } else {
          this.enableMenu('OFERTAS DE FRETE', ofertasFreteChildren);
          this.enableMenu('OFERTAS DE AGREGAMENTO', ofertasAgregamentoChildren);
          this.enableMenu('COLETAS', coletasChildren);
        }
        if (!this.userEtcData.dadosControleAcesso.trafego.value) {
          this.disableMenu('GESTÃO DE FRETES');
        } else {
          this.enableMenu('GESTÃO DE FRETES', gestaoFretesChildren);
        }
        const menuItem = this.listMenus.find(f => f.name === 'CRÉDITO');
        if (!this.userEtcData.dadosControleAcesso.financeiro.value) {
          if (menuItem) {
            menuItem.isDenied = true;
            menuItem.children?.forEach((item) => {
              if (item.type === 'link') {
                item.state = 'access-denied';
              } else {
                item.subchildren?.forEach((subitem) => {
                  subitem.state = 'access-denied';
                });
              }
            });
          }
        } else {
          if (menuItem) {
            menuItem.isDenied = false;
            menuItem.children?.forEach((item, index) => {
              if (item.type === 'link') {
                item.state = creditoChildren[index].state;
              } else {
                item.subchildren?.forEach((subitem, subindex) => {
                  if (subitem.type === 'link') {
                    subitem.state = antecipacoesSubChildren[subindex].state;
                  } else {
                    subitem.state = 'access-denied';
                  }
                });
              }
            });
          }
        }
      }
      if (this.loggedUser.perfil!.find(f => f.id === 'etc_trafego') && this.loggedUser.perfil!.find(f => f.id === 'etc_financeiro')) {
        // Remover o menu administrativo
        const indexAdm = this.listMenus.findIndex(f => f.name === 'ADMINISTRATIVO');
        this.listMenus.splice(indexAdm, 1);
      } else if (this.loggedUser.perfil!.find(f => f.id === 'etc_financeiro')) {
        // Remover o menu administrativo
        const indexAdm = this.listMenus.findIndex(f => f.name === 'ADMINISTRATIVO');
        this.listMenus.splice(indexAdm, 1);
        // Remover o menu tráfego
        const indexTrafego = this.listMenus.findIndex(f => f.name === 'TRÁFEGO');
        this.listMenus.splice(indexTrafego, 1);
      } else if (this.loggedUser.perfil!.find(f => f.id === 'etc_trafego')) {
        // Remover o menu administrativo
        const indexAdm = this.listMenus.findIndex(f => f.name === 'ADMINISTRATIVO');
        this.listMenus.splice(indexAdm, 1);
        // Remover o menu financeiro
        const indexFinanceiro = this.listMenus.findIndex(f => f.name === 'CRÉDITO');
        this.listMenus.splice(indexFinanceiro, 1);
      }
    } else if (this.accessType === AccessTypeEnum.frotanetadmin) {
      this.moduloName = 'admin-frotanet';
    } else if (this.accessType === AccessTypeEnum.callcenteradmin) {
      this.moduloName = 'admin-callcenter';
    } else {
      this.moduloName = '';
    }
  }

  selectMenuAfterRefresh() {
    this.refreshPage = false;
    const returnSplit = this.urlAtual.split('/');
    this.addMarkItemToMenu(returnSplit[2]);
  }

  addMarkItemToMenu(linkState: string | null) {
    if (linkState === 'access-denied') return;

    if (linkState === 'administrativo-empresa-registrar') {
      linkState = 'administrativo-empresa-listar';
    } else if (linkState === 'administrativo-usuario-registrar') {
      linkState = 'administrativo-usuario-listar';
    } else if (linkState === 'ofertas-fretes-manter') {
      linkState = 'ofertas-fretes-listar';
    } else if (linkState === 'ofertas-agregamento-manter') {
      linkState = 'ofertas-agregamento-listar';
    } else if (linkState === 'motorista-manter') {
      linkState = 'motorista-listar';
    }

    this.menuItems.getAdminMenuItems(this.accessType).forEach(root => {
      if (root.children) {
        root.children.forEach((childMenu) => {
          childMenu.markItem = false;
          if (childMenu.type === 'subchild') {
            if (childMenu.subchildren) {
              childMenu.subchildren.forEach(subChildMenu => {
                if (subChildMenu.state && subChildMenu.state === linkState) {
                  subChildMenu.markItem = true;
                  this.rootMenuSeletected = root.name;
                } else {
                  subChildMenu.markItem = false;
                }
              });
            }
          } else {
            if (childMenu.state === linkState && childMenu.state !== 'access-denied') {
              childMenu.markItem = true;
              this.rootMenuSeletected = root.name;
            }
          }
        });
      }
    });

    if (this.refreshPage) {
      this.selectMenuAfterRefresh();
    }
  }

  redirectMenu(p1: string | null, p2: string | null, p3: string | null, p4: string | null) {
    this.addMarkItemToMenu(p2? p2 : p4);
    let rt = '/';
    if (p1) {
      rt = rt + p1;
    }
    if (p2) {
      rt = rt + '/' + p2;
    }
    if (p3) {
      rt = rt + '/' + p3;
    }
    if (p4) {
      rt = rt + '/' + p4;
    }
    if (this.authService.editAlertForRedirect) {
      Swal.fire({
        title: this.authService.editAlertForRedirectStr,
        text: 'Deseja sair da tela?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
      }).then(x => {
        if (x.value) {
          this.authService.editAlertForRedirect = false;
          this.router.navigate([rt]);
          localStorage.removeItem('dadosUsuario');
        } else if (x.dismiss) {
          const retornoSplit = this.urlAtual.split('/');
          this.addMarkItemToMenu(retornoSplit[2]);
        }
      });
    } else {
      this.router.navigate([rt]);
    }
  }

  disableMenu(menuText: string) {
    const menuItem = this.listMenus.find(f => f.name === menuText);
    if (menuItem) {
      menuItem.isDenied = true;
      menuItem.children?.forEach((item) => {
        item.state = 'access-denied';
      });
    }
  }
  enableMenu(menuText: string, childenItens: ChildrenItems[]) {
    const menuItem = this.listMenus.find(f => f.name === menuText);
    if (menuItem) {
      menuItem.isDenied = false;
      menuItem.children?.forEach((item, index) => {
        item.state = childenItens[index].state;
      });
    }
  }
}

import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "@angular/fire/firestore";

export class DadosGeraisEmpresaModel {
  id: string;
  cnpjFormatted: string;
  name: string;
  nomeFantasia: string;
  razaoSocial: string;
  type: string;
  ref: any;

  constructor() {
    this.id = "";
    this.cnpjFormatted = "";
    this.name = "";
    this.nomeFantasia = "";
    this.razaoSocial = "";
    this.type = "";
  }
}

export class DadosBancarioModel {
  codigo: string;
  nome: string;
  cnpj: string;
  numeroAgencia: string;
  numeroConta: string;
  tipoConta: string;

  constructor() {
    this.codigo = '';
    this.nome = '';
    this.cnpj = '';
    this.numeroAgencia = '';
    this.numeroConta = '';
    this.tipoConta = 'Conta Pagamento';
  }

  static firestoreConverter = {
    toFirestore: (entityData: DadosBancarioModel): DocumentData => {
      const returnData: any = {};
      returnData.codigo = entityData.codigo;
      returnData.nome = entityData.nome;
      returnData.cnpj = entityData.cnpj;
      returnData.numeroAgencia = entityData.numeroAgencia;
      returnData.numeroConta = entityData.numeroConta;
      returnData.tipoConta = entityData.tipoConta;
      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new DadosBancarioModel(), snapshotData);
    }
  }
}

import { DocumentData, SnapshotOptions } from "@angular/fire/compat/firestore";
import { QueryDocumentSnapshot } from "firebase/firestore";

export class TipoCnpjModel {
  id: string;
  name: string;

  constructor() {
    this.id = '';
    this.name = '';
  }

  getTipoCnpjName(id: string) {

    switch (id) {
      case 'mei':
        return 'MEI';

      case 'mei-caminhoneiros':
        return 'MEI Caminhoneiros';

      case 'etc-mei':
        return 'ETC MEI';

      default:
        return '';
    }
  }

  static firestoreConverter = {
    toFirestore: (entityData: TipoCnpjModel): DocumentData => {

      const returnData: any = {
        id: entityData.id,
        name: entityData.name
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new TipoCnpjModel(), snapshotData);
    }
  }
}

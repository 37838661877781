import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-divider',
  templateUrl: './title-divider.component.html',
  styleUrls: ['./title-divider.component.scss']
})
export class TitleDividerComponent {

  @Input() title: string = '';

  constructor() { }

}

<div fxLayout="row wrap" class='frota-net'>
  <div fxFlex='100' class='title'>
    {{ pageTitle ? pageTitle : title }}
  </div>

  <div fxFlex='100'>
    <mat-card>
      <mat-card-content>
        <div fxLayout="row wrap" class="total">
          <div fxFill class='m-b-20'>
            <ng-content></ng-content>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

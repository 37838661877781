import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

  transform(dateOrTimestamp: Date | firebase.firestore.Timestamp | null) {

    if (dateOrTimestamp) {

      let date: Date;

      if (dateOrTimestamp instanceof Date || typeof dateOrTimestamp === 'string') {

        date = dateOrTimestamp;
      }
      else {

        date = (dateOrTimestamp as firebase.firestore.Timestamp).toDate();
      }

      return date;
    }
    else {

      return null;
    }
  }
}

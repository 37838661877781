import {
  adminChildren,
  coletasChildren,
  creditoChildren,
  gestaoFretesChildren,
  ofertasAgregamentoChildren,
  ofertasFreteChildren
} from "src/app/core/route/admin-etc.routes";
import { Menu } from "./menu-items.model";

const adminEtcMenu: Menu[] = [
  {
    state: '',
    name: 'ADMINISTRATIVO',
    type: 'sub',
    children: [
      { ...adminChildren[0] },
      { ...adminChildren[1] },
    ],
  },
  {
    state: '',
    name: 'OFERTAS DE FRETE',
    type: 'sub',
    children: [
      { ...ofertasFreteChildren[0] },
      { ...ofertasFreteChildren[1] },
    ]
  },
  {
    state: '',
    name: 'OFERTAS DE AGREGAMENTO',
    type: 'sub',
    children: [
      { ...ofertasAgregamentoChildren[0] },
      { ...ofertasAgregamentoChildren[1] },
    ]
  },
  {
    state: '',
    name: 'COLETAS',
    type: 'sub',
    children: [
      { ...coletasChildren[0] },
      { ...coletasChildren[1] },
    ]
  },
  {
    state: '',
    name: 'GESTÃO DE FRETES',
    type: 'sub',
    children: [
      { ...gestaoFretesChildren[0] },
      { ...gestaoFretesChildren[1] },
      { ...gestaoFretesChildren[2] },
      { ...gestaoFretesChildren[3] },
    ]
  },
  {
    state: '',
    name: 'CRÉDITO',
    type: 'sub',
    children: [
      { ...creditoChildren[0] },
      { ...creditoChildren[1] },
      { ...creditoChildren[2] },
    ]
  }
];

export class MenuItemsEtc {
  getMenuItem(): Menu[] {
    return adminEtcMenu;
  }
}

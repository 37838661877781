import firebase from 'firebase/compat/app';
import { GeneralPhotoModel } from './general-photo';
import { FirestoreDataConverter, QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import { DocumentData, SnapshotOptions } from '@angular/fire/compat/firestore';
import { UtilHandler } from '../core/util.handler';

export class UsuarioCaminModel {
  uid: string;
  name: string;
  cpf: string;
  email: string;
  phone: string;
  photo: string;
  fotoPerfil: FotoPerfilModel;
  isGerenciarViagem: boolean;
  lastStatusUpdate: Date | firebase.firestore.Timestamp | null;
  lastGetUserData: Date | firebase.firestore.Timestamp | null;
  status: string;
  souAutonomo: boolean;
  souAgregado: boolean;
  cnhInfo: CnhInfoModel;
  rntrcInfo: RntrcInfoModel;
  listaCertificacoes: CertificacoesModel[];
  listaVeiculos: TipoVeiculoModel[];
  listaCarrocerias: TipoCarroceriaModel[];

  constructor() {
    this.uid = '';
    this.name = '';
    this.cpf = '';
    this.email = '';
    this.phone = '';
    this.photo = '';
    this.fotoPerfil = new FotoPerfilModel();
    this.isGerenciarViagem = false;
    this.lastStatusUpdate = null;
    this.lastGetUserData = null;
    this.status = '';
    this.souAutonomo = false;
    this.souAgregado = false;
    this.cnhInfo = new CnhInfoModel();
    this.rntrcInfo = new RntrcInfoModel();
    this.listaCertificacoes = [];
    this.listaVeiculos = [];
    this.listaCarrocerias = [];
  }

  getFirebaseObject(): any {
    return {
      uid: this.uid,
      name: this.name,
      cnhInfo: this.cnhInfo.getFirebaseObject(),
      cpf: this.cpf,
      email: this.email,
      phone: this.phone,
      souAutonomo: this.souAutonomo,
      souAgregado: this.souAgregado,
      rntrcInfo: this.rntrcInfo.getFirebaseObject(),
      status: this.status,
      isGerenciarViagem: this.isGerenciarViagem,
      fotoPerfil: this.fotoPerfil.getFirebaseObject(),
      listaCertificacoes: this.listaCertificacoes.map(certificacao => certificacao.getFirebaseObject()),
      listaVeiculos: this.listaVeiculos.map(veiculo => veiculo.getFirebaseObject()),
      listaCarrocerias: this.listaCarrocerias.map(carroceria => carroceria.getFirebaseObject()),
      lastStatusUpdate: '', // this.lastStatusUpdate?.toString() ?? '',
      lastGetUserData: this.lastGetUserData?.toString() ?? '',
      photo: this.photo,
    };
  }
}

export class FotoPerfilModel {
  id: string;
  fileBytes: string;
  fileName: string;
  fileExtension: string;
  filePath: string;

  constructor() {
    this.id = '';
    this.fileBytes = '';
    this.fileName = '';
    this.fileExtension = '';
    this.filePath = '';
  }

  getFirebaseObject(): any {
    return {
      id: this.id ?? '',
      fileBytes: this.fileBytes ?? '',
      fileName: this.fileName ?? '',
      fileExtension: this.fileExtension ?? '',
      filePath: this.filePath ?? '',
    };
  }
}


export class CnhInfoModel {
  numeroCnh: string;
  categoria: CnhCategoriaModel;
  dataValidade: Timestamp | Date | null;
  listaFotosCnh: GeneralPhotoModel[];

  constructor() {
    this.numeroCnh = '';
    this.categoria = new CnhCategoriaModel();
    this.dataValidade = null;
    this.listaFotosCnh = [];
  }

  static firestoreConverter: FirestoreDataConverter<CnhInfoModel> = {
    toFirestore: (entityData: CnhInfoModel): DocumentData => {
      const returnData: any = {
        numeroCnh: entityData.numeroCnh,
        categoria: entityData.categoria,
        dataValidade: entityData.dataValidade,
        listaFotosCnh: entityData.listaFotosCnh
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      snapshotData["createdAt"] = UtilHandler.convertToDateType(snapshotData["createdAt"]);
      return Object.assign(new CnhInfoModel(), snapshotData);
    }
  }

  getFirebaseObject(): any {
    return {
      numeroCnh: this.numeroCnh,
      categoria: {
        id: this.categoria.id,
        name: this.categoria.name,
        index: this.categoria.index,
      },
      dataValidade: this.dataValidade ?? null,
      fotoCnh: this.listaFotosCnh.map((m) => m.getFirebaseObject()),
    };
  }
}

export class CnhCategoriaModel {
  index: number;
  id: string;
  name: string;

  constructor() {
    this.index = 0;
    this.id = '';
    this.name = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: CnhCategoriaModel): DocumentData => {

      const returnData: any = {
        id: entityData.id,
        name: entityData.name,
        index: entityData.index
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new CnhCategoriaModel(), snapshotData);
    }
  }

  getFirebaseObject(): any {
    return {
      id: this.id,
      index: this.index,
      name: this.name,
    }
  }
}

export class RntrcInfoModel {
  numero: string;
  dataValidade: Date | firebase.firestore.Timestamp | null;

  constructor() {
    this.numero = '';
    this.dataValidade = null;
  }

  getFirebaseObject(): any {
    return {
      numero: this.numero,
      dataValidade: this.dataValidade ?? null,
    };
  }

  static firestoreConverter = {
    toFirestore: (entityData: RntrcInfoModel): DocumentData => {

      const returnData: any = {
        numero: entityData.numero,
        dataValidade: entityData.dataValidade
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new RntrcInfoModel(), snapshotData);
    }
  }

}

export class CertificacoesModel {
  ativo?: boolean;
  index: number;
  id: string;
  name: string;
  uid?: string;

  constructor() {
    this.ativo = false;
    this.index = 0;
    this.id = '';
    this.name = '';
    this.uid = '';
  }

  getFirebaseObject(): any {
    return {
      ativo: this.ativo,
      index: this.index,
      id: this.id,
      name: this.name,
      uid: this.uid
    };
  }

  static firestoreConverter: FirestoreDataConverter<CertificacoesModel> = {

    toFirestore: (entityData: CertificacoesModel): DocumentData => {

      let returnObject: any = {};

      returnObject.ativo = entityData.ativo;
      returnObject.uid = entityData.uid;
      returnObject.index = entityData.index;
      returnObject.id = entityData.id;
      returnObject.name = entityData.name;

      return returnObject;
    },

    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {

      const snapshotData = snapshot.data(options);
      return Object.assign(new CertificacoesModel(), snapshotData);
    }
  }
}

export class TipoVeiculoModel {
  index: number;
  id: string;
  name: string;
  numeroPlaca: string;
  idVeiculo: string;
  fotosVeiculos: GeneralPhotoModel[];

  constructor() {
    this.index = 0;
    this.id = '';
    this.name = '';
    this.numeroPlaca = '';
    this.idVeiculo = '';
    this.fotosVeiculos = [];
  }

  getFirebaseObject(): any {
    return {
      index: this.index,
      id: this.id,
      name: this.name,
      numeroPlaca: this.numeroPlaca,
      idVeiculo: this.idVeiculo,
      fotosVeiculos: this.fotosVeiculos,
    };
  }
}

export class TipoVeiculoTacModel {
  idTipoVeiculo: string;
  listaFotos: GeneralPhotoModel[];
  listaPlacasRenavam: PlacaRenavamModel[];
  tipoCarroceria: TipoCarroceriaModel;
  tipoComposicao: TipoComposicaoModel;

  constructor() {
    this.idTipoVeiculo = '';
    this.listaFotos = [];
    this.listaPlacasRenavam = [];
    this.tipoCarroceria = new TipoCarroceriaModel();
    this.tipoComposicao = new TipoComposicaoModel();
  }

  static firestoreConverter = {
    toFirestore: (entityData: TipoVeiculoTacModel): DocumentData => {

      const returnData: any = {
        idTipoVeiculo: entityData.idTipoVeiculo,
        listaFotos: entityData.listaFotos,
        listaPlacasRenavam: entityData.listaPlacasRenavam,
        tipoCarroceria: TipoCarroceriaModel.firestoreConverter.toFirestore(entityData.tipoCarroceria),
        tipoComposicao: entityData.tipoComposicao
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new TipoVeiculoTacModel(), snapshotData);
    }
  }

  getFirebaseObject(): any {
    return {
      idTipoVeiculo: this.idTipoVeiculo,
      listaFotos: this.listaFotos,
      listaPlacasRenavam: this.listaPlacasRenavam,
      tipoCarroceria: this.tipoCarroceria.getFirebaseObject(),
      tipoComposicao: this.tipoComposicao.getFirebaseObject(),
    };
  }
}

export class PlacaRenavamModel {
  numeroPlaca: string;
  numeroRenavam: string;
  tipo: string;

  constructor() {
    this.numeroPlaca = '';
    this.numeroRenavam = '';
    this.tipo = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: PlacaRenavamModel): DocumentData => {

      const returnData: any = {
        numeroPlaca: entityData.numeroPlaca,
        numeroRenavam: entityData.numeroRenavam,
        tipo: entityData.tipo
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new PlacaRenavamModel(), snapshotData);
    }
  }

  getFirebaseObject(): any {
    return {
      numeroPlaca: this.numeroPlaca,
      numeroRenavam: this.numeroRenavam,
      tipo: this.tipo,
    };
  }
}

export class TipoCarroceriaModel {
  id: string;
  index: number;
  name: string;
  fotosCarroceria?: GeneralPhotoModel[];

  constructor() {
    this.id = '';
    this.index = 0;
    this.name = '';
    this.fotosCarroceria = [];
  }

  static firestoreConverter = {
    toFirestore: (entityData: TipoCarroceriaModel): DocumentData => {

      const returnData: any = {
        id: entityData.id,
        index: entityData.index,
        name: entityData.name,
        fotosCarroceria: entityData.fotosCarroceria
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new TipoCarroceriaModel(), snapshotData);
    }
  }

  getFirebaseObject(): any {
    return {
      id: this.id,
      index: this.index,
      name: this.name,
    };
  }
}

export class TipoComposicaoModel {
  carroceriaFixa: boolean;
  descriptions: string[];
  group: string;
  id: string;
  name: string;
  qtdCargaTon: number;
  qtdPlacasRenavam: number;
  qtdTotalEixos: number;
  tipoVeiculo: string;

  constructor() {
    this.carroceriaFixa = false;
    this.descriptions = [];
    this.group = '';
    this.id = '';
    this.name = '';
    this.qtdCargaTon = 0;
    this.qtdPlacasRenavam = 0;
    this.qtdTotalEixos = 0;
    this.tipoVeiculo = '';
  }

  static firestoreConverter = {
    toFirestore: (entityData: TipoComposicaoModel): DocumentData => {

      const returnData: any = {
        carroceriaFixa: entityData.carroceriaFixa,
        descriptions: entityData.descriptions,
        group: entityData.group,
        id: entityData.id,
        name: entityData.name,
        qtdCargaTon: entityData.qtdCargaTon,
        qtdPlacasRenavam: entityData.qtdPlacasRenavam,
        qtdTotalEixos: entityData.qtdTotalEixos,
        tipoVeiculo: entityData.tipoVeiculo
      };

      return returnData;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
      const snapshotData = snapshot.data(options);
      return Object.assign(new TipoComposicaoModel(), snapshotData);
    }
  }

  getFirebaseObject(): any {
    return {
      carroceriaFixa: this.carroceriaFixa,
      descriptions: this.descriptions,
      id: this.id,
      name: this.name,
      qtdCargaTon: this.qtdCargaTon,
      qtdPlacasRenavam: this.qtdPlacasRenavam,
      qtdTotalEixos: this.qtdTotalEixos,
      tipoVeiculo: this.tipoVeiculo
    };
  }
}

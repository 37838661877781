import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() buttonType: 'primary' | 'secondary' = 'primary';
  @Output() clickButton = new EventEmitter();

  constructor() { }

  onClick() {

    this.clickButton.emit();
  }
}

//TODO: ajeitar o angular fire aqui pra versão compat da 9
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { AuthService } from 'src/app/core/auth/auth.service';
import Swal from 'sweetalert2';
import { UserModel } from 'src/app/models/UserModel';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { EmailAuthProvider } from '@angular/fire/auth';

@Component({
  selector: 'app-change-password-content',
  templateUrl: './change-password-content.component.html',
  styleUrls: ['./change-password-content.component.scss']
})
export class ChangePasswordContentComponent implements OnInit, OnDestroy {

  loggedUser!: UserModel;
  loggedUserSubscription!: Subscription;

  formAlteraSenha!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private location: Location
  ) {
    this.zerandoForm();
    this.desativaAlert();
  }


  ngOnInit() {
    this.loggedUserSubscription = this.authService.userAuth.subscribe(loggedUser => {
      if (loggedUser) {
        this.loggedUser = loggedUser;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loggedUserSubscription) {
      this.loggedUserSubscription.unsubscribe();
    }
  }

  verificarSenhas() {
    if (this.formAlteraSenha.valid) {
      const senhaAtual = this.formAlteraSenha.controls['senhaAtualCtrl'].value;
      const novaSenha = this.formAlteraSenha.controls['novaSenhaCtrl'].value;

      const cred = EmailAuthProvider.credential(firebase.auth().currentUser!.email ?? '', senhaAtual);
      firebase.auth().currentUser!.reauthenticateWithCredential(cred).then(x => {
        firebase.auth().currentUser!.updatePassword(novaSenha);
        Swal.fire({
          title: 'Senha alterada',
          text: 'Senha atualizada com sucesso',
          icon: 'success'
        });
        this.desativaAlert();
        this.authService.signOut(true);
      }).catch(error => {
        Swal.fire({
          title: 'Senha atual',
          text: 'Senhas atual incorreta',
          icon: 'error'
        });
      });
    }
  }

  back() {
    Swal.fire({
      title: 'Itens alterados não serão salvos.',
      text: 'Deseja sair da tela?',
      icon: 'warning'
    });
    this.zerandoForm();
    this.location.back();
  }

  zerandoForm() {
    function passwordMatchValidator(formGroup: FormGroup) {
      let error = null;
      if (formGroup.get('novaSenhaCtrl')!.value !== formGroup.get('confirmaSenhaCtrl')!.value) {
        error = { 'mismatch': true };
      }
      formGroup.get('confirmaSenhaCtrl')!.setErrors(error);
    }
    this.formAlteraSenha = this.formBuilder.group({
      senhaAtualCtrl: new FormControl('', [Validators.required, Validators.minLength(8)]),
      novaSenhaCtrl: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmaSenhaCtrl: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, { validator: passwordMatchValidator });
  }

  desativaAlert() {
    this.authService.editAlertForRedirect = false;
    this.authService.editAlertForRedirectStr = '';
  }

  changeData() {
    this.authService.editAlertForRedirect = true;
    this.authService.editAlertForRedirectStr = 'Itens alterados não serão salvos.';
  }

}

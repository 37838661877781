<div style="width: 100%; height: 50px; background-color: yellow; padding-top: 10px;"
  fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!isCompatibleBrowser">
  <p style="text-align: center;">
    Para aproveitar o máximo das funcionalidades do Sistema FROTANET,
    recomendamos que seu uso seja realizado através dos navegadores abaixo:<br>
    Chrome, Safari ou Edge.
  </p>
</div>
<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
